import * as React from "react";
import { FC } from "react";
import { ContactEntrypoint } from "../../entrypoints/contact";
import { LANG } from "../../common/types/localization";
import { DefaultLayout } from "../../components/layout/default";

export default (): FC => (
  <DefaultLayout lang={LANG.deCH} entrypoint={"contact"} page={"kontakt"}>
    <ContactEntrypoint lang={LANG.deCH} />
  </DefaultLayout>
);
